.section-financement {
  border-radius: 1000px/110px;
  width: 110vw;
  transform: translateX(-5%);
  padding: 50px;
  overflow: hidden;
}

.section-financement::-webkit-scrollbar {
  display: none;
  overflow: hidden;
}
.overflow-scroll {
  overflow: hidden;
}
