.card-container {
  position: relative;
  margin-top: 100px;
  width: 300px; /* ou la largeur de votre image */
  height: 500px; /* ou la hauteur de votre image */
}

.card {
  position: absolute;
  width: 100%; /* cela garantit que l'image couvre tout le conteneur */
  height: auto; /* maintient l'aspect ratio de l'image */
  top: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.card:nth-child(1) {
  transform: rotate(10deg) translate(-80px, -60px);
  z-index: 2;
}

.card:nth-child(2) {
  transform: rotate(-20deg) translate(230px, -60px);
  z-index: 3;
}

.card:nth-child(3) {
  transform: rotate(-5deg) translate(80px, 250px);
  z-index: 5;
}
