#app a {
  text-decoration: none;
  color: #242424;
}

#app li.active a {
  color: #8e6100;
}

#app li {
  background-color: #eeeeee;
  border: 2px white solid;
}

#courtisia-simulator {
  min-height: 600px !important;
  width: 100vw !important;
  padding: 10px;
}

#courtisia-simulator > ul {
  text-align: center;
}

#app > div > div > button {
  position: relative;
  display: inline-block;
  padding: 20px 39px;
  background: blue;
  line-height: 26px;
  color: #ffffff;
  font-weight: 700;
  text-transform: capitalize;
  transition: all 300ms ease;
  cursor: pointer;
  text-align: center;
  z-index: 1;
}

#app > div > div > input {
  border: #3d3d3d 1px solid;
}

#courtisia-simulator .label {
  width: 100% !important;
}
