.cards-scrolling-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  white-space: nowrap;
  /* width: 100vw;
  transform: translateX(-20%); */
}

.cards-scrolling-container::-webkit-scrollbar {
  display: none;
}
