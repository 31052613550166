.notfound-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply; /* Mélanger la couleur avec l'image de fond */
}
